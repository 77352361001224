<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col cols="3">
        <scheduled-devotional-card></scheduled-devotional-card>
      </v-col>
      <v-col cols="9" class="pl-4">
        <devotional-table></devotional-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const ScheduledDevotionalCard = () =>
  import("../cards/ScheduledDevotionalCard.vue");
const DevotionalTable = () => import("../tables/DevotionalTable.vue");

export default {
  components: {
    ScheduledDevotionalCard,
    DevotionalTable
  }
};
</script>
